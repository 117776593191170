<template>
  <div id="main"
       :style="`height:${chartHeight ? chartHeight + 'px' : '262px'}`"></div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    chartLegend: {
      type: Object,
      default: () => { }
    },
    chartName: {
      type: String,
      default: ''
    },
    xAxisData: {
      type: Array,
      default: () => []
    },
    seriesData: {
      type: Array,
      default: () => []
    },
    chartHeight: {
      type: Number,
      default: 0
    },
    legend: {
      type: Object,
      default: () => { }
    },
    grid: {
      type: Object,
      default: () => {
        return {
          top: 10,
          left: 40,
          right: 20,
          bottom: 20,
        }
      }
    }
  },
  data () {
    return {
      setOption: {
        color: '#3278EB',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            axis: 'x'
          }
        },
        // legend: {
        // },
        // grid:{},
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#D8D8D8'
            }
          },
          axisLabel: {
            // interval: index => {
            //   return index % 2 === 0
            // },
            color: '#666666',
            formatter: function (value) {
              return moment(value).format("MM-DD")
            }
          },
          // data: 
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          minInterval: 1,
          axisLine: {
            lineStyle: {
              color: '#D8D8D8'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#666666'
          },
          splitLine: {
            lineStyle: {
              color: '#D8D8D8',
              type: 'dotted'
            },
          },
        },
        series: [
          {
            areaStyle: {
              opacity: 0.1
            },
            itemStyle: {
              opacity: 0
            },
            lineStyle: {
              width: 1
            },
            // name: '流水',
            type: 'line',
            smooth: true,
            // data
          }
        ]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const myChart = this.$echarts.init(document.getElementById('main'));
      this.initChart()
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    })
  },
  methods: {
    initChart () {
      const myChart = this.$echarts.init(document.getElementById('main'));
      myChart.showLoading();
      this.setOption.series[0].name = this.chartName
      this.setOption.xAxis.data = this.xAxisData
      this.setOption.series[0].data = this.seriesData
      this.setOption.legend = this.chartLegend
      this.setOption.legend = this.legend
      this.setOption.grid = this.grid
      myChart.setOption(this.setOption)
      myChart.hideLoading();
    }
  }
}
</script>

<style lang="less" scoped>
#main {
  width: 100%;
}
</style>