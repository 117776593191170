<template>
  <div class="core-wrap"
       v-loading="loading">
    <el-card style="margin-top:20px">
      <div slot="header"
           class="clearfix">
        <div>
          <span class="title">个人数据</span>
          <span class="people">主播<span>{{anchor}}</span>人</span>
          <span class="people">经纪人<span>{{agent}}</span>人</span>
          <span v-role="['administrator']"
                class="people">系统本月收益<span>{{earnings}}</span>元</span>
        </div>
      </div>
      <ul>
        <li v-for="item in coreList"
            :key="item.text"
            :class="`${item.link ? 'coreHover' : ''}`"
            @click="handlerCore(item.link)">
          <div>
            <h6>
              <span :class="item.isBlue ? 'num blue' : 'num red'">{{item.data}}</span>
              <span class="unit">{{item.unit}}</span>
            </h6>
            <span class="text">{{item.text}}
              <el-tooltip class="item"
                          effect="dark"
                          :content="item.toolip"
                          placement="top">
                <span :class="`icon iconfont ${item.icon}`"></span>
              </el-tooltip>
            </span>
          </div>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  data () {
    return {
      loading: true,
      anchor: 0,
      earnings: 0,
      agent: 0,
      coreList: []
    }
  },
  created () {
    this._initState()
    this.incomeFn()
  },
  methods: {
    async incomeFn () {
      const { role } = this.$getStorage("userInfo");
      if (role === 'administrator') {
        const { message } = await api.systemIncomeApi()
        this.earnings = message
      }
    },
    async _initState () {
      const { status_code, message: { boxes, charts, rank } } = await api.coreDataApi()
      // const { message } = await api.taskMytaskApi()
      if (status_code === 200) {
        this.anchor = boxes.anchor_count
        this.agent = boxes.distributor_count
        this.handleSetList(0, boxes.month_flow.toFixed(2), '本月流水')
        this.handleSetList(1, boxes.yes_flow, '昨日流水')
        // this.handleSetList(2, message.effective_anchor_pro.completed, '有效职业主播', true, '人', true, '月流水3万元人民币（30万探币）以上，且满足有效天20天和总时长60小时')
        this.handleSetList(2, boxes.yes_active, '昨日开播')
        // this.handleSetList(3, message.effective_anchor_progress.completed, '有效主播', false, '人', true, '月有效天20天，总时长60小时以上')
        this.handleSetList(3, boxes.yes_contract, '昨日新入驻主播')
        this.handleSetList(4, boxes.yes_distributor_count, '昨日新邀请经纪人')
        this.loading = false
        this.$store.commit('home/setCharts', charts)
        this.$store.commit('home/setRank', rank)
      }
    },
    handleSetList (idx, data, text, isBlue, unit, icon, toolip, link) {
      this.$set(this.coreList, idx, { data, text, isBlue, unit, icon: icon ? 'icon-wenhao' : '', toolip, link })
    },
    handlerCore (path) {
      path && this.$router.push({ path })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  color: #333333;
}
.people {
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  color: #333;
  span {
    color: #3278eb;
  }
}
.reload {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
.clearfix {
  display: flex;
  justify-content: space-between;
}
.el-icon-refresh {
  cursor: pointer;
  font-size: 20px;
  vertical-align: -2px;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 10px;
}
li {
  margin: 0 20px;
  text-align: center;
  margin-bottom: 30px;
}
.red {
  color: #3278eb;
}
.blue {
  color: #3278eb;
}
h6 {
  text-align: center;
  margin-bottom: 5px;
  span {
    font-size: 14px;
    color: #333;
  }
}
.num {
  font-size: 28px;
}
.text,
.iconfont {
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
}
.core-wrap /deep/ .el-card__body {
  padding: 20px 20px 10px 20px;
}
.coreHover {
  cursor: pointer;
}
</style>